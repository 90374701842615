#demoModal {
  z-index: 2;
  background-color: #0007;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#demoModal .content {
  color: #232735;
  background-color: #fff;
  border-radius: 16px;
  gap: 40px;
  max-width: 700px;
  padding: 40px;
  display: flex;
  position: relative;
  box-shadow: 0 14px 24px #58629833;
}

#demoModal .content .title {
  font-family: CirceBold;
  font-weight: initial;
  -webkit-font-smoothing: antialiased;
  font-size: 24px;
  line-height: 32px;
}

#demoModal .content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

#demoModal .content .text {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

#demoModal .content .text svg {
  cursor: pointer;
  position: absolute;
  top: 21px;
  right: 21px;
  fill: #acb2c3 !important;
}

#demoModal .content .form {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

#demoModal .content .form .input-field {
  margin-top: 0;
}

#demoModal .content .form p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

#demoModal .content .form p a {
  color: #536ced;
  text-decoration: none;
}

#demoModal .content .form .input-wrapper.invalid .input-message {
  display: block;
}

#demoModal .content .form .input-wrapper.invalid .input-message:empty {
  display: none;
}

#demoModal .content .form .input-message {
  color: #ff6868;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  display: none;
}

#demoModal .content .form button {
  cursor: pointer;
  font-family: CirceBold;
  font-weight: initial;
  -webkit-font-smoothing: antialiased;
  margin-top: 12px;
  margin-right: 0;
  font-size: 20px;
  line-height: 32px;
}

#demoModal .content .form button.invalid {
  opacity: .5;
  cursor: auto;
}

#demoModal .content .form.invalid > .input-message {
  display: block;
}

#demoModal .content .form.invalid > .input-message:empty {
  display: none;
}

#demoModal .content .form.invalid button {
  opacity: .5;
  cursor: auto;
}

#demoModal .content .confirm, #demoModal .content.success .text, #demoModal .content.success .form {
  display: none;
}

#demoModal .content.success .confirm {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: flex;
}

#demoModal .content.success .confirm button {
  cursor: pointer;
  margin-top: 12px;
}

#demoModal .content.success > * {
  width: 100%;
}

#demoModal .content > * {
  width: 50%;
}

@media only screen and (width <= 732px) {
  #demoModal {
    padding: 0 16px;
  }

  #demoModal .content {
    flex-direction: column;
    gap: 32px;
    max-height: 100%;
    padding: 32px 16px 64px;
    overflow: auto;
  }

  #demoModal .content .title {
    font-family: CirceBold;
    font-weight: initial;
    -webkit-font-smoothing: antialiased;
    font-size: 20px;
    line-height: 32px;
  }

  #demoModal .content p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  #demoModal .content > * {
    width: 100%;
  }
}
/*# sourceMappingURL=demoModal.4ce6ff45.css.map */
