@use "../../public/styles/variables.scss";

#demoModal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #0007;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  display: none;

  .content {
    max-width: 700px;
    background-color: #fff;
    border-radius: 16px;
    padding: 40px;
    gap: 40px;
    display: flex;
    color: variables.$text_01;
    position: relative;
    @include variables.shadow02;

    .title {
      @include variables.P1Bold;
    }

    p {
      @include variables.P3;
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 12px;

      svg {
        position: absolute;
        top: 21px;
        right: 21px;
        cursor: pointer;
        fill: variables.$ui_04 !important;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .input-field {
        margin-top: 0;
      }

      p {
        @include variables.P4;

        a {
          color: variables.$link;
          text-decoration: none;
        }
      }

      .input-wrapper.invalid {
        .input-message {
          display: block;

          &:empty {
            display: none;
          }
        }
      }

      .input-message {
        @include variables.P4;
        color: variables.$error_02;
        display: none;
      }

      button {
        cursor: pointer;
        margin-top: 12px;
        margin-right: 0;
        @include variables.P2Bold;

        &.invalid {
          opacity: 0.5;
          cursor: auto;
        }
      }

      &.invalid {
        > .input-message {
          display: block;

          &:empty {
            display: none;
          }
        }
        button {
          opacity: 0.5;
          cursor: auto;
        }
      }
    }

    .confirm {
      display: none;
    }

    &.success {
      .text, .form {
        display: none;
      }

      .confirm {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        
        button {
          cursor: pointer;
          margin-top: 12px;
        }
      }

      > * {
        width: 100%;
      }
    }

    > * {
      width: 50%;
    }
  }
}

@media only screen and (max-width: 732px) {
  #demoModal {
    padding: 0 16px;
    .content {
      flex-direction: column;
      gap: 32px;
      padding: 32px 16px 64px;
      max-height: 100%;
      overflow: auto;

      .title {
        @include variables.P2Bold;
      }
  
      p {
        @include variables.P4;
      }

      > * {
        width: 100%;
      }
    }
  }
}